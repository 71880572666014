<template>
  <div class="account-list mb-2">
    <template v-if="listLoading">
      <div class="list-loading">
        <label>読み込み中</label>
      </div>
    </template>
    <v-table>
      <thead>
        <tr class="table-header-row">
          <th v-for="(item, index) in header" :key="index" class="table-header column-space" :style="applyLeftStyle(index)">
            <div class="d-flex align-items-center">
              <span :class="addClassIfSortable(item)" @click="emitChangeSortOrder(item)">{{item}}</span>
              <span v-show="sortableItem(item)" style="display: inline-block;">
                <div class="nc-icon nc-stre-up sort-icon" @click="emitChangeSortOrder(item, sortTypes[0])" :style="highLightIfCurrentSort(item, sortTypes[0])" style="display: block;" />
                <div class="nc-icon nc-stre-down sort-icon" @click="emitChangeSortOrder(item, sortTypes[1])" :style="highLightIfCurrentSort(item, sortTypes[1])" style="display: block;" />
              </span>
            </div>
          </th>
          <th v-if="isAdmin">
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="accounts?.length != 0">
          <template v-for="account in accounts" :key="account.id">
            <AccoutListRow
              :isAdmin="isAdmin"
              :account="account"
              :changeStatusAuthority="changeStatusAuthority"
              :listLoading="listLoading"
              :updatingStatus="updatingStatus"
              :deletable="deletable"
              @changeStatus="emitChangeStatus"
              :reload="reload"
              :showFlash="showFlash"
            />
          </template>
        </template>
        <template v-else-if="accounts?.length == 0 && !listLoading">
          <tr>
            <td colspan="9">
              表にデータがありません。
            </td>
          </tr>
        </template>
      </tbody>
    </v-table>
  </div>
</template>

<script>
  import AccoutListRow from "@/components/AccountParts/AccountListRow.vue"
  import { ADMIN_ROLE_TYPE } from "@/services/consts"

  export default {
    props: {
      selfRoleId: {
        type: Number,
        default: 2
      },
      accounts: [],
      changeStatusAuthority: {
        type: Boolean,
        default: false
      },
      listLoading: {
        type: Boolean,
        default: true
      },
      updatingStatus: {
        type: Boolean,
        default: false
      },
      deletable: {
        type: Boolean,
        default: false
      },
      showFlash: {
        type: Function
      },
      reload: {
        type: Function
      }
    },
    data() {
      return {
        header: [
          'ID',
          'ログインID',
          'アカウント名',
          'Eメール',
          '会社区分',
          '所属組織',
          '組織',
          '役割',
          'ドライバーID',
          '電話番号',
          'ステータス',
          '最終更新日',
          '最終更新者',
        ],
        sortableItems: {
          'ID': 'id',
          'ログインID': 'login_id',
          'アカウント名': 'account_name',
          'Eメール': 'email',
          '会社区分': 'officeable_type',
          '所属組織': 'officeable_name',
          '組織': 'department_target_name',
          'ステータス': 'status',
          '最終更新日': 'updated_at',
          '最終更新者': 'updated_by'
        },
        currentSort: { item: 'id', type: 'ASC' },
        sortTypes: ['ASC', 'DESC'],
        officeableTypeNames: {
          ManagementOffice: '管理会社',
          SubManagementOffice: '準管理会社',
          OperationOffice: '運営会社',
          InstallationOffice: '設置会社'
        },
        roles: { 1: '管理者', 2: 'ドライバー' },
      }
    },
    methods: {
      emitChangeSortOrder(sortItem, sortType = null) {
        if (!this.sortableItem(sortItem)) return;

        const item = this.sortableItems[sortItem];
        if (sortType) {
          this.currentSort.type = sortType;
        } else {
          if (this.currentSort.item == item) {
            const type = this.sortTypes.find(type => type != this.currentSort.type);
            this.currentSort.type = type;
          } else {
            this.currentSort.type = this.sortTypes[0];
          }
        }
        this.currentSort.item = item;
        this.$emit('changeSortOrder', this.currentSort.item, this.currentSort.type);
      },
      sortableItem(itemName) {
        return Object.keys(this.sortableItems).includes(itemName);
      },
      addClassIfSortable(itemName) {
        if (!this.sortableItem(itemName)) return;

        return 'sort-icon pr-2';
      },
      highLightIfCurrentSort(item, type) {
        if (this.currentSort.item != this.sortableItems[item] || this.currentSort.type != type) return;

        return 'font-weight: bold;'
      },
      applyLeftStyle(index) {
        if (index == 0) return 'left: 0;';
      },
      emitChangeStatus(params) {
        this.$emit('changeStatus', params);
      }
    },
    computed: {
      isAdmin() {
        return this.selfRoleId == ADMIN_ROLE_TYPE;
      }
    },
    components: {
      AccoutListRow,
    }
  }
</script>

<style>
.account-list {
  position: relative;
}
.account-list .v-table__wrapper {
  max-height: 45em;
  min-height: 20vw;
  overflow: auto;
}
.account-list .v-table__wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.account-list .v-table__wrapper::-webkit-scrollbar-thumb {
  background-color: rgb(150,150,150);
  border-radius: 5px;
  box-shadow: inset 3px 3px 3px rgba(255,255,255,0.2);
}
.account-list .v-table__wrapper::-webkit-scrollbar-track {
  background-color: rgb(220,220,220);
  border-radius: 5px;
  box-shadow: inset 3px -3px 5px rgba(0,0,0,0.2);
}
.account-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
}
.account-list table .fix-td {
  background-color: white;
  position: sticky;
  left: 0;
}
.account-list table .table-header-row th {
  z-index: 1;
}
.account-list table .table-header-row th:first-child {
  z-index: 2;
}
.list-loading {
  height: 100%;
  position: absolute;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.list-loading>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.column-space {
  white-space: nowrap;
}
.account-list .sort-icon:hover {
  cursor: pointer;
  opacity: 0.5;
}
</style>
